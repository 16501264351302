/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { ButtonLogo } from "../ButtonLogo";
import "./style.css";
import instagram from '../../../images/icons/instagram.png';
import facebook from '../../../images/icons/facebook.png';
import linkedin from '../../../images/icons/linkedin.png';

export const Footer = ({ className }) => {
  return (
    <div className={`footer ${className}`}>
      <div className="columns">
        <div className="column">
          <div className="logo-wrapper">
            <ButtonLogo />
          </div>
        </div>
        <div className="small-columns">
          <div className="content-wrapper">
            <div className="content">
              <div className="div">COMPANY</div>
              <div className="footer-links">
                <a href="#about-us"><div className="link">About us</div></a>
                <a href="#how-it-works"><div className="link-2">How it works</div></a>
              </div>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="content">
              <div className="div">RESOURCES</div>
              <div className="footer-links">
                <div className="link">Privacy Policy</div>
                <div className="link-2">Cookies Policy</div>
              </div>
            </div>
          </div>
          <div className="social-media-links">
            <a target="_blank" href="https://instagram.com/pinkstairsdsg?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D">
              <img
                className="social-media-icon"
                alt="Image"
                src={instagram}
                type="image/png"
              />
            </a>
            <a target="_blank" href="https://www.facebook.com/profile.php?id=100092091970514">
              <img
                className="social-media-icon"
                alt="Image"
                src={facebook}
                type="image/png"
              />
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/pinkstairsdsg?trk=organization_guest_main-feed-card_feed-actor-name">
              <img
                className="social-media-icon"
                alt="Image"
                src={linkedin}
                type="image/png"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p className="text">Copyright © 2023 Pink Stairs Design</p>
      </div>
    </div>
  );
};
