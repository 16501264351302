import React from "react";
import { Button } from "../../components/Button";
import { Footer } from "../../components/Footer";
import { NavbarLogoLeft } from "../../components/NavbarLogoLeft";
import "./style.css";
import avatar1 from '../../../images/1.png'
import avatar2 from '../../../images/2.png'
import avatar3 from '../../../images/3.png'
import avatar4 from '../../../images/4.png'

import img0 from '../../../images/img0.png'
import img1 from '../../../images/img1.png'
import img2 from '../../../images/img2.png'
import img3 from '../../../images/img3.png'
import img4 from '../../../images/img4.png'
import img5 from '../../../images/img5.png'
import img7 from '../../../images/img7.png'
import img8 from '../../../images/img8.png'

import company_culture_1 from '../../../images/company_culture_1.png'
import company_culture_2 from '../../../images/company_culture_2.png'
import company_culture_3 from '../../../images/company_culture_3.png'

import see_the_steps from '../../../images/see_the_steps.png'

export const Home = () => {
  return (
    <div id="home" className="home">
      <NavbarLogoLeft className="navbar-logo-left-instance" />
      <div className="hero-heading-right">
        <div className="container">
          <div className="div-wrapper">
            <div className="image-wrapper">
              <div className="image" />
            </div>
          </div>
          <div className="column-2">
            <div className="div-2">
              <div className="step-up-your-game">
                <div className="text-wrapper-2">Step up</div>
                <div className="text-wrapper-3">your game!</div>
              </div>
              <p className="p">UX research, audit and data-driven design services for game developers</p>
            </div>
            <div className="actions">
              <a href="#get-in-touch">
                <Button className="design-component-instance-node" stateProp="default" text="GET IN TOUCH" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="services" className="hero-no-image">
        <div className="frame">
          <div className="title">What do we do?</div>
          <p className="text-wrapper-4">
            Our mission is to bridge the gap between small startups and big studios, ensuring that all game developers,
            regardless of size, can deliver superior-quality games to their players.
          </p>
        </div>
        <div className="columns-2">
          <div className="column-2">
            <div className="quote-wrapper">
              <div className="div-3">
                <div className="author">
                  <img
                    className="image-2"
                    alt="Image"
                    src={avatar1}
                    type="image/png"
                  />
                  <div className="info">
                    <div className="text-wrapper-5">UX/UI Design</div>
                  </div>
                </div>
                <div className="frame-2">
                  <p className="text-2">
                    We will collaborate with your team to craft seamless interactions and intuitive user interfaces to
                    provide meaningful engagement, enhancing the overall gameplay experience of your players.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="column-3">
            <div className="quote-wrapper">
              <div className="div-3">
                <div className="author">
                <img
                    className="image-2"
                    alt="Image"
                    src={avatar2}
                    type="image/png"
                  />
                  <div className="info-2">
                    <div className="text-wrapper-6">UX Research</div>
                  </div>
                </div>
                <div className="frame-2">
                  <p className="text-2">
                    We conduct in-depth user research to gain valuable insights into player behavior, preferences, and
                    pain points. By understanding the target audience&#39;s needs and motivations, we can help you
                    create games that resonate with players on a deeper level.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="column-3">
            <div className="quote-wrapper">
              <div className="div-3">
                <div className="author">
                <img
                    className="image-2"
                    alt="Image"
                    src={avatar3}
                    type="image/png"
                  />
                  <div className="info">
                    <div className="text-wrapper-6">UX Audit</div>
                  </div>
                </div>
                <div className="frame-2">
                  <p className="text-2">
                    Whether you are encountering challenges or preparing for a successful launch, our game audit and
                    market analysis services will provide you with valuable insights into performance of your game and
                    identify opportunities for improvement
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="column-2">
            <div className="quote-wrapper">
              <div className="div-3">
                <div className="author">
                  <img
                    className="image-2"
                    alt="Image"
                    src={avatar4}
                    type="image/png"
                  />
                  <div className="info">
                    <div className="text-wrapper-6">UX Strategy</div>
                  </div>
                </div>
                <div className="frame-2">
                  <p className="text-2">
                    By analyzing player behaviors and preferences, we develop tailored retention strategies that
                    increase player engagement and encourage them to keep coming back to your game.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial-column">
        <div className="frame-3">
          <div className="title">How do we work?</div>
          <p className="text-wrapper-4">
            We aim to provide all studios with high-end UX research and exceptional UI design services, enabling them to
            compete with AAA companies.
          </p>
        </div>
        <div className="small-columns-wrapper">
          <div className="small-columns-2">
            <img
              className="image-3"
              alt="Image"
              src={img8}
              type="image/png"
            />
            <div className="column-4">
              <div className="content-2">
                <div className="quote">
                  <div className="info-wrapper">
                    <div className="info-3">
                      <div className="text-wrapper-6">Data-Driven Approach</div>
                    </div>
                  </div>
                  <p className="text-3">
                    We base the best decisions on real data. With comprehensive analytics and user feedback, we optimize
                    your game&#39;s user experience for success. Our data-backed insights empower Game Developers to
                    make informed design decisions and provide actionable recommendations for improvement.
                  </p>
                </div>
              </div>
            </div>
            <div className="column-4">
              <div className="content-3">
                <div className="quote">
                  <div className="info-wrapper">
                    <div className="info-3">
                      <div className="text-wrapper-6">Education</div>
                    </div>
                  </div>
                  <p className="text-3">
                    Offering educational value is very important to us. Therefore, in addition to our core services, we
                    also offer UX workshops for Game Developers. These workshops serve as a valuable addition to those
                    who wish to gain a deeper understanding of UX principles, best practices, and how to apply them
                    effectively in their projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="small-container-wrapper">
        <div className="small-container">
          <div className="make-your-game-more-wrapper">
            <p className="make-your-game-more">
              <span className="span">Make your game more </span>
              <span className="text-wrapper-7">successful </span>
              <span className="span">in just a few steps</span>
            </p>
          </div>
          <a href="#get-in-touch">
              <Button className="button-2" stateProp="default" text="GET IN TOUCH" />
          </a>
        </div>
      </div>
      <div className="features-list">
        <div className="columns-3">
          <div className="column-5">
            <div className="intro-wrapper">
              <div className="div-2">
                <div className="title-2">See the value</div>
                <p className="text-wrapper-8">
                  What our service is like? How does it change your life? Check out the benefits of incorporating UX
                  research and analysis in your game design process.
                </p>
              </div>
            </div>
            <img
              className="image-4"
              alt="Image"
              src={img7}
              type="image/png"
            />
          </div>
          <div className="column-6">
            <div className="div-3">
              <div className="info-wrapper">
                <div className="info-3">
                  <div className="text-wrapper-9">Our features</div>
                </div>
              </div>
              <div className="div-4">
                <p className="description">
                  <span className="text-wrapper-10">Low client engagement. </span>
                  <span className="text-wrapper-11">
                    Carrying out the project requires very little input on your end.{" "}
                  </span>
                </p>
              </div>
              <div className="div-4">
                <p className="description">
                  <span className="text-wrapper-10">One-time effort. </span>
                  <span className="text-wrapper-11">One project provides many longtime benefits.</span>
                </p>
              </div>
              <div className="div-4">
                <p className="description">
                  <span className="text-wrapper-10">Affordability. </span>
                  <span className="text-wrapper-11">
                    Our service is in a reachable price range even for small startups.
                  </span>
                </p>
              </div>
            </div>
            <div className="div-3">
              <div className="info-wrapper">
                <div className="info-3">
                  <div className="text-wrapper-9">Your benefits</div>
                </div>
              </div>
              <div className="div-4">
                <p className="description">More insights mean less concerns &amp; more certainty.</p>
              </div>
              <div className="div-4">
                <div className="description">Making decisions becomes easier.</div>
              </div>
              <div className="div-4">
                <p className="description">
                  Our service leads to better product, to increased player retention, and eventually to more revenue.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="how-it-works" className="columns-wrapper">
        <div className="columns-4">
          <div className="column-7">
            <div className="small-container-2">
              <div className="div-2">
                <div className="text-wrapper-12">See the steps</div>
                <p className="text-wrapper-8">
                  Are you wondering how does it all work? Here’s the process explained in few steps.
                </p>
              </div>
            </div>
            <div className="div-4">
              <img
                className="image-5"
                alt="Image"
                src={img0}
                type="image/png"
              />
              <p className="description-2">
                First of all - reach out! Just drop us an email&nbsp;&nbsp;through the contact form or at
                contact@pinkstairsdesign.com
              </p>
            </div>
            <div className="div-4">
              <img
                className="image-5"
                alt="Image"
                src={img1}
                type="image/png"
              />
              <p className="description-2">
                We schedule a discovery call and chat about how we can bring your game to the next level. We work under
                NDA and define the scope of your project.
              </p>
            </div>
            <div className="div-4">
              <img
                className="image-5"
                alt="Image"
                src={img2}
                type="image/png"
              />
              <p className="description-2">
                After the call, you&#39;ll receive a project proposal via email. If we&#39;re a good fit, you get a
                contract and initial invoice.
              </p>
            </div>
            <div className="div-4">
              <img
                className="image-5"
                alt="Image"
                src={img3}
                type="image/png"
              />
              <p className="description-2">
                We work together to bring you maximum value and insights. You’re always in the loop.
              </p>
            </div>
            <div className="div-4">
              <img
                className="image-5"
                alt="Image"
                src={img4}
                type="image/png"
              />
              <p className="description">
                When the project is complete, you&#39;ll receive all insights as a complete report which we are going to
                discuss on the call to give you the best idea on how to drive more success to your game.
              </p>
            </div>
            <div className="div-4">
              <img
                className="image-5"
                alt="Image"
                src={img5}
                type="image/png"
              />
              <p className="description-2">Upon completion of the project you also get the final invoice.</p>
            </div>
          </div>
          <div className="img-wrapper">
            <img
              className="image-6"
              alt="Image"
              src={see_the_steps}
              type="image/png"
            />
          </div>
        </div>
      </div>
      <div className="features-list-2">
        <div className="columns-5">
          <div className="column-8">
            <div className="content-4">
              <div className="intro">
                <div className="title-3">FAQ</div>
                <p className="description-3">Here you can find the answers to most frequently asked questions.</p>
              </div>
            </div>
          </div>
          <div className="div-3">
            <div className="feature">
              <p className="description-4">Why do I need your services?</p>
              <p className="description-5">
                Our services provide valuable insights and solutions, that allow you to increase player retention, and
                eventually lead to more revenue.
              </p>
            </div>
            <div className="feature">
              <p className="description-4">
                How can I improve&nbsp;&nbsp;&nbsp;&nbsp;my game&#39;s performance in the market?
              </p>
              <p className="description-5">
                <span className="text-wrapper-13">
                  It’s good to have you here! Our services are designed specifically to answer this question.{" "}
                </span>
                <span className="text-wrapper-14">Get in touch</span>
                <span className="text-wrapper-13"> with us and we’ll find a solution. </span>
              </p>
            </div>
            <div className="feature">
              <div className="description-4">How much is it?</div>
              <p className="description-6">
                <span className="text-wrapper-13">
                  Usually pricing depends on the project. We work on T&amp;M basis, based on scope and milestones
                  defined in the contract. The overall cost varies depending on which specialists are involved in the
                  project. In order to get specific estimation for your project, please{" "}
                </span>
                <span className="text-wrapper-14">get in touch</span>
                <span className="text-wrapper-13">. We’re happy to help!</span>
              </p>
            </div>
            <div className="feature">
              <p className="description-4">How long does it take?</p>
              <p className="description-5">
                This usually depends on the project, its production stage and defined requirements. While short analysis
                can take a week, a larger and more demanding research may take up to few months.
              </p>
            </div>
            <div className="feature">
              <p className="description-4">Do I get an NDA?</p>
              <p className="description-5">Yes. We care about safety a lot, hence we always work under NDA.</p>
            </div>
          </div>
        </div>
        <a href="#get-in-touch">
          <Button className="button-2" stateProp="default" text="GET IN TOUCH" />
        </a>
      </div>
      <div id="about-us" className="team-rectangles">
        <div className="container-2">
          <div className="section-title">
            <div className="text-wrapper-15">Company culture</div>
            <p className="text-wrapper-4">
              At Pink Stairs Design, our goal is to empower game developers by providing them with game-changing
              research and design services.
            </p>
          </div>
          <div className="columns-6">
            <div className="card">
              <div className="image-wrapper-2">
                <img
                  className="image-7"
                  alt="Image"
                  src={company_culture_1}
                  type="image/png"
                />
              </div>
              <div className="info-4">
                <div className="name">Energy</div>
                <p className="description-7">
                  Our diverse and dynamic team is the driving force behind our company. As young creatives, we&#39;re
                  constantly generating fresh and exciting ideas.
                </p>
              </div>
            </div>
            <div className="card-2">
              <div className="image-wrapper-2">
                <img
                  className="image-7"
                  alt="Image"
                  src={company_culture_2}
                  type="image/png"
                />
              </div>
              <div className="info-4">
                <div className="name">Responsibility</div>
                <p className="description-7">
                  We view giving back to the community as a social responsibility. That&#39;s why we periodically run
                  free workshops and educate about the value of UX in games design.
                </p>
              </div>
            </div>
            <div className="card-3">
              <div className="image-wrapper-2">
                <img
                  className="image-7"
                  alt="Image"
                  src={company_culture_3}
                  type="image/png"
                />
              </div>
              <div className="info-4">
                <div className="name">Empowerment</div>
                <p className="description-7">
                  We approach our work with partner-like, uplifting attitude, aiming to empower our clients and instill
                  confidence in their products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial-stack">
        <div className="small-container-3">
          <div className="content-wrapper-2">
            <div className="div-5">
              <p className="span-wrapper">
                <span className="text-wrapper-16">
                  “(...) when I talked about your workshop, I realized how much I remembered and was able to explain to
                  them!! UI and especially UX were taught at my uni, but in a very bad way - only about making apps on
                  mobile, no real feedback to our work, not telling us what the real point and strengths of these
                  disciplines are. And you fixed it, at least for me! It seems exciting and meaningful now.”
                </span>
              </p>
            </div>
            <div className="div-5">
              <div className="image-wrapper-3">
                <div className="image-9" />
              </div>
              <div className="info-5">
                <div className="text-wrapper-17">Kathrin</div>
                <div className="author-job">Workshop Attendee</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="get-in-touch">
        <div className="contact-form">
          <div className="container-3">
            <div className="div-5">
              <div className="text-wrapper-15">Get in touch</div>
              <p className="text-wrapper-18">Drop us an email and take your game to the next level!</p>
            </div>
            <div className="mailto">
              <a className="mailto-link" href="mailto:contact@pinkstairsdesign.com">contact@pinkstairsdesign.com</a>
            </div>
          </div>
          <a href="#home">
            <Button className="button-5" divClassName="button-4" stateProp="default" text="BACK TO TOP" />
          </a>
        </div>
      </div>
      <Footer className="footer-instance" />
    </div>
  );
};
