/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";
import logo from '../../../images/logo.png'


export const ButtonLogo = () => {
  return (
    <div className="button-logo">
      <img
        className="vector-logo-final"
        alt="Vector logo final"
        src={logo}
        type="image/png"
      />
    </div>
  );
};
