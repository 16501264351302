/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Button } from "../Button";
import "./style.css";
import logo from '../../../images/logo.png'

export const NavbarLogoLeft = ({ className }) => {
  return (
    <div className={`navbar-logo-left ${className}`}>
      <div className="navbar-container">
        <div className="navbar-content">
          <img
            className="navbar-brand"
            alt="Navbar brand"
            src={logo}
            type="image/png"
          />
          <div className="navbar-menu">
            <div className="navbar-link">
              <a href="#about-us"><div className="text-wrapper">About</div></a>
            </div>
            <div className="navbar-link">
              <a href="#services"><div className="text-wrapper">Services</div></a>
            </div>
            <a href="#get-in-touch">
              <Button className="button-instance" stateProp="default" text="GET IN TOUCH" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
